.radio-element{
    cursor: pointer;
}

.scrollable-container{
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 8px;
}

.selected{
    border:2px solid #004fd4; 
}

.scrollable-container::-webkit-scrollbar {
    width: 8px;
    margin-left: 5px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #fafcfc;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #d9e8ec;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .input:focus {
    border-color: #d9edec;
  }

  .changeRxmodal__size{
    width: 700px;
}


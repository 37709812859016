.textinput[type="text"] {
    width: 200px;
    height: 20px;
    padding-right: 50px;
}

.submitinput[type="submit"] {
    margin-left: -50px;
    height: 25px;
    width: 50px;
    background: blue;
    color: white;
    border: 0;
    -webkit-appearance: none;
}
li::before {
    content: "";
    -webkit-flex: 0 !important;
    -ms-flex: 0 !important;
    flex: 0 !important;
    padding: 0px 0px;
}


textarea::-webkit-scrollbar {
    width: 18.5px;
}

/* Track */
textarea::-webkit-scrollbar-track {
    background: #FAFCFC;
    width: 5px;
    cursor: pointer !important;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
    background: #D9E8EC;
    border-left: 3px solid #FAFCFC;
    border-right: 3px solid #FAFCFC;
    cursor: pointer !important;
    border-radius: 7px;
}

/* Handle on hover */
textarea::-webkit-scrollbar-thumb:hover {
    background: #D9E8EC;
    cursor: pointer !important;
}

textarea::-webkit-scrollbar-thumb:focus {
    background: #D9E8EC;
    cursor: pointer !important;
}



div__textarea--border {
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    font-family: 'Montserrat', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    color: var(--terciary);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
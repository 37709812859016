.message__checkbox{
    border-radius: 7px;
    border: 2px solid #d9e8ec;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.icon__archive{
    width: 20px;
    height: 24px;
    cursor: pointer;
}
.icon__file{
    width: 16px;
    height: 16px;
    cursor: pointer;
}


.stat__value{
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: 18px;
    left: -30%;
    background: #336383;
    width: 20px;
    border-radius: 100px;
    height: 20px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat__icon__val {
    position: relative;
    display: flex;
    border: none;
    outline: none;
    padding: 12px;
    border-radius: 16px;
}

.stat__icon__badge {
    position: absolute;
    background: #336383;
    color: #fff;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    padding: 3px;
}

.stat__icon {
    border-radius: 16px;
    padding: 12px;
}


.description-style {
    font-size: 18px;
    font-weight: 600;
}
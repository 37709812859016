.insurance-container {
    border: 1px solid var(--d9e8ec);
    border-radius: 10px !important;
  }
  
  .eligibility-container {
    border-radius: var(--radius-7);
  }
  
  .eligibility-container.success {
    background-color: var(--success-new);
  }
  
  .eligibility-container.reject {
    background-color: var(--warning-new);
  }
  
  .eligibility-text-margin {
    margin-top: 0.1em;
  }
  
  .insurance-border-start {
    border-left: 1px solid var(--d9e8ec);
  }
  
  .insurance-check-container {
    border-radius: var(--radius-10);
    background-color: var(--disabled);
  }
  
  .custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }
  .input:focus {
    border-color: #d9edec;
}
.search__container{
  /* padding: 1px; */
}
.li:hover{
  background-color: rgba(211, 211, 211, 0.2)
  
}
.suggestion__list{
  list-style-type: none;
  padding-left: 0;
  position: absolute;
  list-style: none;
  overflow: auto;
  max-height: 250px;
  border: 0px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index:5;
  background-color: var(--white);
}
.suggestion__list li{
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 8px;
  display: flex;
  font-family: Montserrat;
  font-size: 14px;
  border-bottom: 1px solid #d9e8ec;
}

.tags__input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 32px;
  width: 100%;
  padding: 0 8px;

}

.tag__title {
  margin-top: 3px;
  font-size: 12px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--terciary);
  padding: 4px 8px;
  font-weight: 600;
  list-style: none;
  border-radius: 10px;
  margin: 0 8px 8px 0;
  background: var(--d9e8ec);
}

.tag__close__icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: var(--secondary);
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

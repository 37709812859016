
.search__container{
    padding: 1.2px;
}
.input:focus {
    border-color: #d9edec;
}

.sig:focus{
    border-color: #d9edec;
}

select option:checked  {
    background-color: #d9e8ec;
}


.checkbox-accent:checked{
    background-color: #d9e8ec;
    box-shadow: 0 0 0 0.1rem var(--bcd);
    border-color: #d9e8ec;
}

.checkbox-accent:focus{
    box-shadow: 0 0 0 0.1rem var(--bcd);
    outline: none;

}

.checkbox-accent:indeterminate{
    background-color: white;
    box-shadow: none;
    outline: none;
}

.sig__info--border{
    box-shadow: 0px 3px 6px #0000000F;
border: 2px solid #D9E8EC;
border-radius: 10px;
}
.suggestion__list{
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border: 0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index:5;
    background-color: var(--white);
}
.suggestion__list li{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #d9e8ec;
}
.li:hover{
    background-color: rgba(211, 211, 211, 0.2)
    
}
.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    height: 40px;
}

select::placeholder {
    font-style: italic;
    font-size: 14px;
}

.checkBox{
    height: 16px;
    width: 16px;
    border-radius: 5px !important;
}

.row--border{
    border-top: 1px solid #d9e8ec;
    border-left: 1px solid #d9e8ec;
    border-right: 1px solid #d9e8ec;
    
}

.row__head{
    border-top: 1px solid #d9e8ec;
    border-left: 1px solid #d9e8ec;
    border-right: 1px solid #d9e8ec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px;
}

.filter{
    width: 152px;
height: 44px;
background: #FAFCFC 0% 0% no-repeat padding-box;
border: 1px solid #D9E8EC;
border-radius: 10px;
}


.border--bottom{
    border-bottom: 1px solid #D9E8EC;
    border-bottom-left-radius: 10px ;
    border-bottom-right-radius: 12px ;
}

.pageNumbers{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
}
.pageButtons{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
}
/* .pageNumbers li{
    cursor: pointer;
    font-size: 14px;
    color: var(--secondary);
    padding: 16px;
} */
.active__list__item{
    font-weight: 600;
}
.next__btn{
    height: 32px;
    width: 32px;
}
.prev__btn{
    height: 32px;
    width: 32px;
    /* transform: rotate(180deg); */
}

.list__item{
    cursor: pointer;
    font-size: 14px;
    color: var(--secondary);
    padding: 16px;
}
.list__item__btn{
    cursor: pointer;
    font-size: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        
}

select::placeholder{
    font-style: italic;
    font-size:14px;
}
.custom-modal-style {
    width: 60vw;    
    max-width: 90vw;
    }
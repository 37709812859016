.patient-encounter-card-container{
    border: 1px solid #d9e8dc;
    border-radius: 10px;
    box-shadow: 0px 2px 12px #0000000F;
}

.avatar-section{
    border-top-left-radius: 10px;
}

.button-section{
    border-top-right-radius: 10px;
}

.button-view{
    width: 146px;
    height: 40px;
}
.button-view:hover{
    color:'#004fd4';
}

.collapsible-section{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #F2F7F8;
}

.button-view{
    background: #d9e8ec 0% 0% no-repeat padding-box;
  color: #004fd4;
  font: normal normal 600 14px/24px Montserrat;
    letter-spacing: 0px;
    /* color: #004fd4; */
    opacity: 1;
    border-radius: 10px;
}

.margin-horizontal{
    margin-left: 0px;
    margin-right: 0px;

}
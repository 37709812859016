.urgnt_chkbks[type="checkbox"] {
    border: 2px solid #D9E8EC;
    border-radius: 7px;
    border-color: #D9E8EC;
    width:20px;
    height:20px;
    background-color: #FAFCFC !important;
}

.urgnt_chkbks[type="checkbox"]:checked {
    border-radius: 7px;
    border-color: #D9E8EC !important;
    background-color: #D9E8EC !important;
}

.urgnt_chkbks[type="checkbox"]:focus {
    border-radius: 7px;
    border-color: #D9E8EC !important;
}

.msg-input {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-color: #D9E8EC;
    border: 1px solid #D9E8EC;
}

.msg-input-disabled {
    background: #F2F7F8 0% 0% no-repeat padding-box !important;
    border-color: #D9E8EC;
    border: 1px solid #D9E8EC;
}

.msg-input:focus {
    border-color: #D9E8EC;
}

.d9e8ec_button {
    width: 170px;
    background: #D9E8EC;
}

.d9e8ec_button:hover {
    background: #E9F3F5 !important;
    color: #004FD4 !important;
    box-shadow: 0px 13px 16px #A2D1DE66;
    opacity: 1;
}

.d9e8ec_button:focus {
    background: #BFD6DD 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.d9e8ec_button:disabled {
    background:  #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}

.send_button {
    width: 170px;
    background: #004FD4;
}

.send_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.send_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.send_button:disabled {
    background:  #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}

.file_upload {
    width: 165px;
    background: #004FD4;
}

.file_upload:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.file_upload:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.file_upload:disabled {
    background:  #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}
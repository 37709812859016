input[type="email"]::placeholder {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat';
}

input[type="email"] {
    font-family: 'Montserrat';
}

.loginBtn {
    font-family: 'Montserrat';
    font-size: 14px;
    border: 1px solid #004FD4;
}

.blue_task_button {
    background: var(--blue);
}

.blue_task_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.blue_task_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.blue_task_button:disabled {
    background: #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}
.ul-style {
  list-style-type: none;
  color: #999999;
  margin-left: 0px;
}

.ul-style li:before {
  content: '\2013';
  position: absolute;
  margin-left: -15px;
}

.idme_button_size {
  width: 300px;
  height: 50px;
  margin-left: 14px;
}

.log_to_idme_task_button {
  /* width: 130px; */
  background: #D9E8EC;
}

.log_to_idme_task_button:hover {
  background: #E9F3F5 !important;
  color: #004FD4 !important;
  box-shadow: 0px 13px 16px #A2D1DE66;
  opacity: 1;
}

.log_to_idme_task_button:focus {
  background: #BFD6DD 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  opacity: 1;
}

.log_to_idme_task_button:disabled {
  background: #F2F7F8 0% 0% no-repeat padding-box !important;
  color: #A2D1DE !important;
  opacity: 1;
  cursor: not-allowed !important;
}
.task_button {
  width: 23.5em;
  height: 3.5em;
  background: #004FD4;
}

.task_button:hover {
  background: #2C75EF !important;
  color: #ffffff !important;
  box-shadow: 0px 3px 3.5px #004FD466;
  opacity: 1;
}

.task_button:focus {
  background: #0038A7 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  opacity: 1;
}

.task_button:disabled {
  background: #F2F7F8 0% 0% no-repeat padding-box !important;
  color: #A2D1DE !important;
  opacity: 1;
  cursor: not-allowed !important;
}

.custom-modal-style {
  width: 22vw;
  max-width: 90vw;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.inputFocus:focus {
  border: #fff !important
}
.icon__file {
    width: 15px;
    height: 16px;
    cursor: pointer;
}

.icon__archive {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.icon__close {
    line-height: 12px;
    width: 18px;
    font-size: 8pt;
    color: #336383;
    cursor: pointer;
}

.message__container__item {
    max-height: 55vh;
    min-height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.message__container__item::-webkit-scrollbar {
    display: none;
}

.container__footer {
    border: 1px solid #d9e8ec;
    box-shadow: 0px -8px 16px #00000014;
    height: 70px;
}
.reply__forward__container{
    background-color: var(--white);
    /* max-height: ; */
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -8px 16px #0000000F;
}
.close__button{
    line-height: 12px;
    width: 18px;
    font-size: 8pt;
    color: var(--secondary);
    position: absolute !important;
    top: 15;
    right: 15;
    cursor: pointer;
}

.appointmentmodal__style{
    box-shadow: 0px 3px 16px #0000000F;
    border: 1px solid #D9E8EC;
    border-radius: 10px;
}
.chevron__left__div{
    height: 40px;
    width: 40px;
    /* transform: rotate(180deg); */
    /* text-align: center; */
    
}
.message__type__button{
    width: 100px;
    height: 32px;
}

.dropdown__menue__items{
    z-index:  999999;
    box-shadow: 0px 9px 13px #33638326;
    border: 1px solid #D9E8EC;
    border-radius: 0px 0px 10px 10px;
    /* min-width: 18vw; */
    margin-left:-10px;
    margin-top:14px;
}

.patient__dropdown__menue__items{
    z-index:  999999;
    box-shadow: 0px 9px 13px #33638326;
    border: 1px solid #D9E8EC;
    border-radius: 0px 0px 10px 10px;
    /* min-width: 18vw; */
    margin-left:-25px;
    margin-top:14px;
}
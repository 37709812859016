.input:focus {
    border-color: #d9edec;
}

.hpiinput[type=checkbox] {
    background-color: #fafcfc !important;
    border-color: #d9e8ec !important;
    width: 20px !important; 
    height: 20px !important; 
    margin-top: 3px !important;
}

.pi--margin-top {
    margin-top: 10px !important;
}

.hpiinput[type=checkbox]:checked {
    background-color: #d9e8ec !important;
    border-color: #d9e8ec !important;
    box-shadow: none;
}

.hpiinput[type=checkbox]:focus {
    background-color: #d9e8ec !important;
    border-color: #d9e8ec !important;
    box-shadow: none;
}

.pi__checkbox {
    margin-left: 10px;
}

.pi--border-top {
  border-top: 1px solid #d9e8ec;
}
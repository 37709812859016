.patient-billing-card {
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    padding-bottom: 1rem;
}

.patient-billing-left-section-bg {
    background-color: #D9E8EC;
}

.patient-billing-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block-start: 3rem;
    padding-block-end: 3rem;

}

.patient-billing-card-amount-due {
    font-weight: 600;
    font-size: 28px;
}

.patient-billing-amount-negative {
    color: var(--red);
}

.patient-billing-card-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    /* padding-right: 1rem; */
}

@media (max-width: 767px) {
    .patient-billing-card-actions-container {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        padding-right: 0;
    }
}

@media (max-width: 1285px) {
    .patient-billing-card-actions-container {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        padding-right: 0;
    }
}


.patient-billing-card-action {
    color: #004fd4;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    background-color: var(--d9e8ec);
    gap: 1rem;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding-block-start: 0.5rem;
    /* border-top: 1px solid #d9e8ec; */
    padding-block-end: 0.5rem;
    cursor: pointer;
}

.patient-billing-card-action>span:hover {
    text-decoration: underline;
}

.patient-billing-amount-positive {
    color: #097969;
}

.patient-billing-last-paid {
    font-size: 12px;
    color: #f8a02e;
    font-weight: 600;
}

.patient-billing-amount-negative {
    color: var(--red);
}
.refresh-icon:hover {
    cursor: pointer;
}

/* Secondary Background Button */
/* .refresh-icon-title{
    background-color: #336383;
    font-weight: 600 !important;
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 12px;
    outline: none;
} */


.refresh-icon-title {
    font-weight: 700 !important;
    color: #0d6efd;
    outline: none;
    padding: 0;
}


.refresh-icon-title:active {
    outline: none;
}

.refresh-icon-title:focus {
    outline: none;
}


/* .refresh-icon-title:hover{
    color: white;
    background-color: rgba(51, 99, 131, 0.98);
    box-shadow: 5px 6px 20px -2px rgba(51,99,131,0.7);
    -webkit-box-shadow: 5px 6px 20px -2px rgba(51,99,131,0.7);
    -moz-box-shadow: 5px 6px 20px -2px rgba(51,99,131,0.7);
} */

.refresh-icon-title:hover {
    color: #0a58ca;
}

.rotate-icon {
    rotate: 360deg;
    animation: rotate 1s infinite linear;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
.pageNumbers{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
}
.pageButtons{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
}
.next__btn{
    height: 32px;
    width: 32px;
}
.prev__btn{
    height: 32px;
    width: 32px;
    /* transform: rotate(180deg); */
}

.list__item{
    cursor: pointer;
    font-size: 14px;
    color: var(--secondary);
    padding: 16px;
}
.list__item__btn{
    cursor: pointer;
    font-size: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.active__list__item{
    font-weight: 600;
}
/* .custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        
} */

/* select::placeholder{
    font-style: italic;
    font-size:14px;
} */

.modal__size {
    min-width: 490px !important;
    /* height: 664px !important; */
    /* right: 12% */
}


.refill_input:disabled {
    background-color: #F2F7F8 !important;
    border-color: #ccc;
    border: 1px solid #D9E8EC;
    cursor: not-allowed !important;
}

/* Button Styling according to style guide */
.task_button {
    width: 12rem;
    background: #004FD4;
}

.task_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.task_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.task_button:disabled {
    background: #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}

.blue_button {
    width: 130px;
    background: #004FD4;
}

.blue_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.blue_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.blue_button:disabled {
    background: #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}

.deny_button {
    width: 130px;
    background: #D9E8EC;
}

.deny_button:hover {
    background: #E9F3F5 !important;
    color: #004FD4 !important;
    box-shadow: 0px 13px 16px #A2D1DE66;
    opacity: 1;
}

.deny_button:focus {
    background: #BFD6DD 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.deny_button:disabled {
    background: #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}
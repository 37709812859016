

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
    outline: none;
}

input[type="time"]:focus{
    outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url('../../../../assets/icons/Icon-Calendar.svg');
    margin-right: "0px";
    margin-left: "0px";
}

.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        
}

select::placeholder{
    font-style: italic;
    font-size:14px;
}
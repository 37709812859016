.close__btn{
    line-height: 12px;
    width: 18px;
    font-size: 8pt;
    color: #336383;
    position: absolute;
    top: 15;
    right: 15;
    cursor: pointer;
}
.action__button{
    width:198px;
    height: 40px;
    color: var(--blue);
}
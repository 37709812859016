.modal__width{
    width: 70%;
    max-width: 70%;
}

.modal__border{
    border:1px solid #d9e8ec;
    box-shadow: 0px 8px 16px #00000014;
}
.input:focus {
    border-color: #d9edec;
}

.search__container{
    padding: 1.2px;
}
.li:hover{
    background-color: rgba(211, 211, 211, 0.2)
    
}
.input__time{
    color: var(--blue);
    background: #F2F7F8 0% 0% no-repeat padding-box;
    font-weight: 600;
    border-radius: 10px;
    font-family: Montserrat;
    text-transform: capitalize;
    border: 1px solid #D9E8EC;
    height: 40px;
    width: 76px;
}
.suggestion__list{
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border: 0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index:5;
    background-color: var(--white);
}
.suggestion__list li{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #d9e8ec;
}
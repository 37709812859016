.message__scroll{
    max-height:70vh;
    overflow-y:scroll;
}

.message__scroll::-webkit-scrollbar{
    display: none;
}
.checkbox__all{
    border-radius: 7px;
    border: 2px solid #d9e8ec;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.icon__archive{
    width: 20px;
    height: 24px;
    cursor: pointer;
}
.icon__trash{
    width: 16px;
    height: 16px;
    cursor: pointer;
}
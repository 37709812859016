.insurance-container {
    border: 1px solid var(--d9e8ec);
    border-radius: 10px !important;
  }
  
  .eligibility-container {
    border-radius: var(--radius-7);
  }
  
  .eligibility-container.success {
    background-color: var(--success-new);
  }
  
  .eligibility-container.reject {
    background-color: var(--warning-new);
  }
  
  .eligibility-text-margin {
    margin-top: 0.1em;
  }
  
  .insurance-border-start {
    border-left: 1px solid var(--d9e8ec);
  }
  
  .insurance-check-container {
    border-radius: var(--radius-10);
    background-color: var(--disabled);
  }
  
  .custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }
  
.task_button {
    /* width: 12rem; */
    /* background: #004FD4; */
    margin-left: 3rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 1.75rem !important;
}

/* .task_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.task_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.task_button:disabled {
    background:  #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
} */

.b {
    border-right: 1px solid #d9edec;
    border-top-right-radius: 10px;
    border-top: 1px solid #d9edec;
    border-bottom: 1px solid #d9edec;
    border-bottom-right-radius: 10px;
}

.box-border {
    border: 1px solid #d9edec;
}

td.table__tr--background {
    background-color: rgba(217, 232, 236, 0.3);
}

.tr--stripe {
    background-color: #FAFCFC;
}




table.pvt tr td {
    padding-left: 32px;
    padding-right: 32px;


}

table.pvt thead tr th {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 10px;
    padding-bottom: 10px;

}

table.pvt thead tr th {
    border: 1px solid #d9e8ec;
}






.border__tr {
    border-left: 1px solid #d9edec;
    border-right: 1px solid #d9edec;
    border-top: 1px solid #d9edec;
    border-bottom: 1px solid #d9edec;
}
.input:focus {
    border-color: #d9edec;
}
.sig{
    border-radius: 10px;
}
.sig:focus{
    border-color: #d9edec;
}

select option:checked  {
    background-color: #d9e8ec;
}


.checkbox-accent:checked{
    background-color: #d9e8ec;
    box-shadow: 0 0 0 0.1rem var(--bcd);
    border-color: #d9e8ec;
}

.checkbox-accent:focus{
    box-shadow: 0 0 0 0.1rem var(--bcd);
    outline: none;

}

.checkbox-accent:indeterminate{
    background-color: white;
    box-shadow: none;
    outline: none;
}

.sig__info--border{
    box-shadow: 0px 3px 6px #0000000F;

border-radius: 10px;
}
.suggestion__list{
    list-style-type: none;
    padding-left: 0;
    margin: 2px 0 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index:10;
    background-color: var(--white);
}
.suggestion__list li{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #d9e8ec;
}
.cancel__button{
    width: 197px;
}
.add__button{
    width: 197px;
}

.custom__select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23004FD4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
        
}



select::placeholder{
    font-size:12px;
}
.dose__form{
    height: 38px;
}




.scroll__bar::-webkit-scrollbar{
    width: 0.5em;
  }
  
  .scroll__bar::-webkit-scrollbar-track{
    background: #FAFCFC;
    border: 1px solid #D9E8EC;
    border-radius: 15px;
    margin-right: -20px;
  }
  
  .scroll__bar::-webkit-scrollbar-thumb{
    background: #D9E8EC;
    border-radius: 15px;
  }
  
  .scroll__bar::-webkit-scrollbar-thumb:hover{
    background: #D9E8EC;
    opacity: 0.8;
    border-radius: 15px;
  }
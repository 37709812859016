.chevron__left__div{
    height: 37px;
    width: 38px;
}
.message__tab{
    font-size: 14px;
    font-weight: 600;
    text-transform: initial !important;
    color: #336383;
}
.new__message__btn{
    
    border: 1px solid #004FD4;
}

.msg_button {
    background: #004FD4;
}

.msg_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466 !important;
    opacity: 1;
}

.msg_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}

.msg_button:disabled {
    background:  #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}
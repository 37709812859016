.balance-card {
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10rem;
}


.patient-billing-card-amount-due {
    font-weight: 600;
    font-size: 28px;
}

.patient-billing-amount-negative {
    color: var(--red);
}


.patient-billing-amount-positive {
    color: #097969;
}

.person_item {
    display: flex;
    flex-direction: row;
    gap: 3px;
}
.li:hover {
    background-color: rgba(211, 211, 211, 0.2)
}

.consent_suggestions {
    list-style-type: none;
    width: 28vw;
    cursor: pointer;
    padding-left: 0;
    margin: 2px 0 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
    background-color: white;
}

.search__list__item {
    padding-top: 12px;
    /* width: 26vw; */
    padding-bottom: 12px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #d9e8ec;
    cursor: pointer;

}
.make__scrollable{
    position: "absolute";
    height:"60vh";
    overflow:"scroll";
}

input[type="text"]::placeholder{
    font-style: italic;
    font-size:14px;
    color:'#dddddd';
    opacity: 0.5;
}
.searchBar{
    height: 40px;
}
.li:hover{
    background-color: rgba(247, 247, 248, 1) !important;
}


/* Scroll Bar */
.scroll__bar::-webkit-scrollbar{
    width: 0.5em;
    height: 0.5em;
  }
  
  .scroll__bar::-webkit-scrollbar-track{
    background: #FAFCFC;
    border: 1px solid #D9E8EC;
    border-radius: 10px;
    /* margin-right: -20px; */
  }
  
  .scroll__bar::-webkit-scrollbar-thumb{
    background: #D9E8EC;
    border-radius: 10px;
  }
  
  .scroll__bar::-webkit-scrollbar-thumb:hover{
    background: #D9E8EC;
    opacity: 0.8;
    border-radius: 10px;
  }
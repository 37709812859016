/* .scrollable-container::-webkit-scrollbar {
    width: 8px;
    margin-left: 5px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #fafcfc;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #d9e8ec;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  } */

  .renewalRxmodal__size{
    width: 700px;
}


.icon__file{
    width: 15px;
    height: 16px;
    cursor: pointer;
}
.icon__archive{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.icon__close{
    line-height: 12px;
    width: 18px;
    font-size: 8pt;
    color: #336383;
    cursor: pointer;
}
.message__container{
    max-width: 50vw;
    min-height: 55vh;
    margin-left: auto;
}
.message__container__item{
    max-height: 40vh;
    min-height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.message__container__item::-webkit-scrollbar{
    display: none;
}
.message__container__footer{
    box-shadow: 0px -8px 16px #00000014;
    height: 70px;
}
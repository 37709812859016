.textinput[type="text"] {
    width: 200px;
    height: 20px;
    padding-right: 50px;
}

.submitinput[type="submit"] {
    margin-left: -50px;
    height: 25px;
    width: 50px;
    background: blue;
    color: white;
    border: 0;
    -webkit-appearance: none;
}

.chevron__left__div{
    height: 37px;
    width: 38px;
}

.account__tab{
    font-size: 14px !important;
    font-weight: 600;
    text-transform: initial !important;
    color: #336383;
}

.page__title-border{
    border-bottom: 1px solid #d9e8ec;
}

.horizontal-rule{
    border-bottom: 1px solid #d9e8ec;
}

.license-list :first-child > div{
    background-color: white;
}

.btn_submit{
    width: 19em;
}


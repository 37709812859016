.div__wrapper {
  position: relative;
}


.fieldset-title {
  margin: 0px !important;
  display: inline-block;
  background-color: white;
  position: relative;
  top: 0.75rem;
  left: 0.6rem;
  padding-left: 4px;
  padding-right: 4px;
}

.p-1rem {
  padding: 1rem !important;
}
.soup__container{
  max-height: 75vh;
  min-height: 75vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.custom-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

}

.scroll__bar::-webkit-scrollbar{
  width: 0.5em;
}

.scroll__bar::-webkit-scrollbar-track{
  background: #FAFCFC;
  border: 1px solid #D9E8EC;
  border-radius: 15px;
  margin-right: -20px;
}

.scroll__bar::-webkit-scrollbar-thumb{
  background: #D9E8EC;
  border-radius: 15px;
}

.scroll__bar::-webkit-scrollbar-thumb:hover{
  background: #D9E8EC;
  opacity: 0.8;
  border-radius: 15px;
}
.message__search__container{
    width: 396px ;
}
.suggestion__container{
    position: absolute;
    width:396px;
    height: 40vh;
    z-index: 99999999;
}
.suggestion_list{
    list-style-type: none;
    padding-left: 0;
    z-index: 99999999;
    border-top: 0;
    background-color: var(--white);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.make__scrollable{
    position: "absolute";
    height:"60vh";
    overflow:"scroll";
}

input[type="text"]::placeholder{
    font-style: italic;
    font-size:14px;
    color:'#dddddd';
    opacity: 0.5;
}
.searchBar{
    height: 40px;
}
.li:hover{
    background-color: rgba(247, 247, 248, 1) !important;
}
.paid {
  border-radius: 10px;
  border-color: var(--secondary);
  background-color: var(--bcd);
  border-width: 8px;
  padding: 8px;
  color: var(--secondary);
  display: inline-block; 
  white-space: pre-wrap; 
  word-wrap: break-word; 
}

.un-paid {
  border-radius: 10px;
  border-color: #fff;
  background-color: var(--warning);
  border-width: 8px;
  padding: 8px;
  color: #fff;
  display: inline-block; 
  white-space: pre-wrap; 
  word-wrap: break-word; 
}


.pageNumbers{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
  }
  .pageButtons{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
  }
  /* .pageNumbers li{
    cursor: pointer;
    font-size: 14px;
    color: var(--secondary);
    padding: 16px;
  } */
  .active__list__item{
    font-weight: 600;
  }
  .next__btn{
    height: 32px;
    width: 32px;
  }
  .prev__btn{
    height: 32px;
    width: 32px;
    /* transform: rotate(180deg); */
  }
  
  .list__item{
    cursor: pointer;
    font-size: 14px;
    color: var(--secondary);
    padding: 16px;
  }
  .list__item__btn{
    cursor: pointer;
    font-size: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
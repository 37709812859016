/* .reply__forward__container{
    background-color: var(--white);
    min-height: 35vh;
    height: 35vh;
    margin-bottom: -110px;
    position: absolute;
    bottom: 0;
    width: 50%;
    overflow-y: scroll;
    box-shadow: 0px -8px 16px #0000000F;
} */

.rf-container{
    overflow-x: hidden;
    width: 50%;
    /* min-height: 40vh; */
    /* height: 35vh; */
    /* max-height:40vh; */
}
.recipient-container{
    max-width: 35vw;
}
.container__footer {
    border: 1px solid #d9e8ec;
    box-shadow: 0px -8px 16px #0000000F;
    height: 70px;
    
}

.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--terciary);
	padding: 0 8px;
    font-weight: 600;
	list-style: none;
	border-radius: 10px;
	margin: 0 8px 8px 0;
	background: var(--d9e8ec);
}
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 150px;
}

.scroll::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  background: #FAFCFC;
  width: 5px;
  cursor: pointer !important;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #A2D1DE;
  border-left: 3px solid #FAFCFC;
  border-right: 3px solid #FAFCFC;
  cursor: pointer !important;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: #A2D1DE;
  cursor: pointer !important;
}

.scroll::-webkit-scrollbar-thumb:focus {
  background: #A2D1DE;
  cursor: pointer !important;
}
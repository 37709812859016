.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

}

select::placeholder {
    font-style: italic;
    font-size: 14px;
}

.custom-modal-style {
    width: 60vw;
    max-width: 90vw;
}

.appointmentmodal__style{
    box-shadow: 0px 3px 16px #0000000F;
border: 1px solid #D9E8EC;
border-radius: 10px;
}

.appointmentmodal__border--bottom{
    border-bottom: 1px solid #d9e8ec;
}

.blue_button {
    width: 12rem;
    background: #004FD4;
}

.blue_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.blue_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.blue_button:disabled {
    background:  #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}

.billing-card-container {
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding: 16px;
    min-height: 10rem;
}

.cc_radio[type="radio"] {
    border: 2px solid #D9E8EC !important;
    border-radius: 10px !important;
    width: 20px;
    height: 20px;
    background-color: #FAFCFC !important;
}

.mt-20 {
    margin-top: 20px;
}

.cc_radio[type="radio"]:checked {
    border-radius: 10px;
    border-color: #D9E8EC !important;
    background-color: #EC609B !important;
    box-shadow: none;
}

.borderColor {
    border: 2px solid #336383;
    border-radius: 10px;
}

.task_button {
    width: 100%;
    height: 3rem;
    background: #004FD4;
}

.task_button:hover {
    background: #2C75EF !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #004FD466;
    opacity: 1;
}

.task_button:focus {
    background: #0038A7 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}


.cancel_button {
    width: 100%;
    height: 3rem;
    background: white;
    color: #ff0000;
    border: 1px solid #ff0000;
}

.cancel_button:hover {
    background: #ff0000bd !important;
    color: #ffffff !important;
    box-shadow: 0px 13px 16px #ff000061;
    opacity: 1;
}

.cancel_button:focus {
    background: #ff0000 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    opacity: 1;
}


.task_button:disabled {
    background: #F2F7F8 0% 0% no-repeat padding-box !important;
    color: #A2D1DE !important;
    opacity: 1;
    cursor: not-allowed !important;
}

.hostedFields {
    height: 32px !important;
    margin-bottom: 0.4em !important;
    margin-left: 1em;
    display: block;
    /* background-color: transparent; */
    /* color: rgba(0, 0, 0, .87); */
    border: none;
    outline: 0;
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative !important;
}

/* .cc_radio[type="radio"]:focus {
    border-radius: 10px;
    border-color: #D9E8EC !important;
} */
.input:focus {
    border-color: #d9edec;
}

.sig:focus{
    border-color: #d9edec;
}

select option:checked  {
    background-color: #d9e8ec;
}


.checkbox-accent:checked{
    background-color: #d9e8ec;
    box-shadow: 0 0 0 0.1rem var(--bcd);
    border-color: #d9e8ec;
}

.checkbox-accent:focus{
    box-shadow: 0 0 0 0.1rem var(--bcd);
    outline: none;

}

.checkbox-accent:indeterminate{
    background-color: white;
    box-shadow: none;
    outline: none;
}

.sig__info--border{
    box-shadow: 0px 3px 6px #0000000F;
border: 2px solid #D9E8EC;
border-radius: 10px;
}

.medication-title-row{
    background-color: var(--fafcfc);
    border-top: 1px solid #DAE9EC;
    border-bottom: 1px solid #DAE9EC;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
}
.checkBox__container{
    height: 20px;
    width: 20px;
    
}
.checkBox{
    border-radius: 5px !important;
    height: 16px;
    width: 16px;
}
.order__prescription__btn{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 144px;

}


.scroll__bar::-webkit-scrollbar{
    width: 0.5em;
  }
  
  .scroll__bar::-webkit-scrollbar-track{
    background: #FAFCFC;
    border: 1px solid #D9E8EC;
    border-radius: 15px;
    margin-right: -20px;
  }
  
  .scroll__bar::-webkit-scrollbar-thumb{
    background: #D9E8EC;
    border-radius: 15px;
  }
  
  .scroll__bar::-webkit-scrollbar-thumb:hover{
    background: #D9E8EC;
    opacity: 0.8;
    border-radius: 15px;
  }
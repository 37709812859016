.message-header{
    display: flex;
    gap:0.5em;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.message-header.message-sender{
    flex-direction: row;
}

.message-header.message-receiver{
    flex-direction: row-reverse !important;
}

.message-content{
    
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-top: 0.5em;
    /* padding: 0.2em; */
    display: flex;
}

.message-content-text{
    border-radius: 5px;
    width: fit-content;
    padding: 0.4em;
    background-color: var(--white);
}

.chat-container{
    background-color: #f2f7f8;
    border-radius: 5px;
 
    height: 100%;
    overflow-y: scroll;
}

.chat-container-header{
    display: flex;
    justify-content: space-between;
    padding-left: 0.8em;
    padding-right: 0.8em;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 0.6em;
    padding-bottom: 0.4em;
    position: sticky;
    top:0;
    background-color: #f2f7f8;
    z-index: 10;
    margin-bottom: 0.4em;
    border-bottom: 1px solid var(--d9e8ec);
}


.chat-container::-webkit-scrollbar{
    width: 0.3em;
   
}


.chat-container::-webkit-scrollbar-track{
    background-color: #F2F7F8 !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    
}

.chat-container::-webkit-scrollbar-thumb{
    background-color: 	hsl(193, 33%, 89%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.chat-container::-webkit-scrollbar-thumb:hover{
    background-color: 	hsl(193, 33%, 60%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.send-button{
    background-color: #a2d1de7e;
    width: 40px;
    height: 40px;
}

.new-message-container{
    position: sticky;
    bottom: 0;
}
.tags__input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 32px;
    width: 100%;
    padding: 0 8px;

}

.tag__title {
    margin-top: 3px;
    font-size: 12px;
}

.input__text {
    flex: 1;
    border: none;
    outline: none;
    height: 46px;
    font-size: 14px;
    width: 100%;

}

.input__text:focus {
    border: none;
    outline: none;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.tag {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--terciary);
    padding: 4px 8px;
    font-weight: 600;
    list-style: none;
    border-radius: 10px;
    margin: 0 8px 8px 0;
    background: var(--d9e8ec);
}

.tag__close__icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: var(--secondary);
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}
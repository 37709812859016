.border__right {
    border-right: 1px solid #d9e8ec;
    padding-right: 4px;
}

.border__bottom {
    border-bottom: 1px solid #d9e8ec;
}

.cursor__pointer {
    cursor: pointer;
}
.view__btn{
    width: 140px;
height: 40px;
background: #D9E8EC 0% 0% no-repeat padding-box;
border: 2px solid #D9E8EC;
border-radius: 10px;
}

.row--border{
    /* border-top: 1px solid #d9e8ec; */
    border-left: 1px solid #d9e8ec;
    border-right: 1px solid #d9e8ec;
}

.row--border--upper{
    border-top: 1px solid #d9e8ec;
}

.chevron__left__div{
    height: 37px;
    width: 38px;
}

.row__head{
    border-top: 1px solid #d9e8ec;
    border-left: 1px solid #d9e8ec;
    border-right: 1px solid #d9e8ec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.filter{
    width: 152px;
height: 44px;
background: #FAFCFC 0% 0% no-repeat padding-box;
border: 1px solid #D9E8EC;
border-radius: 10px;
}

.search{
    width: 190px;
}

.border--bottom{
    border-bottom: 1px solid #D9E8EC;
}
.input:focus {
    border-color: #d9edec;
}
.pi--border {
  border: 1px solid #d9e8ec;
  border-radius: 12px;
}

.pi--border__textarea {
    height: 100px;
    border: none;
    resize: none;
    background-color: #fafcfc;
}

.pi--border__textarea:focus {
    border: none;
    background-color: #fafcfc;
}

.pi--border__textarea:disabled {
    border: none;
    background-color: #fafcfc;
    cursor: not-allowed !important;
}

.hpiinput[type=checkbox] {
    background-color: #fafcfc !important;
    border-color: #d9e8ec !important;
    width: 20px !important; 
    height: 20px !important; 
    margin-top: 12px !important;
}

.pi--margin-top {
    margin-top: 10px !important;
}

.hpiinput[type=checkbox]:checked {
    background-color: #d9e8ec !important;
    border-color: #d9e8ec !important;
    box-shadow: none;
}

.hpiinput[type=checkbox]:focus {
    background-color: #d9e8ec !important;
    border-color: #d9e8ec !important;
    box-shadow: none;
}

.pi__checkbox {
    margin-left: 10px;
}

.pi--border-top {
  border-top: 1px solid #d9e8ec;
}
*{
    font-family: 'Montserrat';
}
.modal__header{
    line-height: 12px;
    width: 18px;
    font-size: 8pt;
    color: var(--secondary);
    position: absolute;
    top: 15;
    right: 15;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: 'Montserrat', sans-serif;
}

:root {
  --primary: #ec609b;
  --secondary: #336383;
  --terciary: #323232;
  --terciary-10: #dee2e6;
  --danger: #ff0000;
  --success: #45c49e;
  --warning: #f8a02e;
  --disabled: #f2f7f8;
  --white: #fff;
  --bcd: #bcd6da;
  --d9e8ec: #d9e8ec;
  --blue: #004fd4;
  --lightBlue: #004fd41a;
  --red: #fd0d1b;
  --green: #18c383;
  --disabledText: #a2d1de;
  --darkSecondary: #1f4c6a;
  --grey: #70707070;
  --darkblue: #031b2b;
  --fafcfc: #fafcfc;
  --black: #3C4043;

  /* Heading Styles Variables */
  --h1-font-size: 32px;
  --h1-line-spacing: 34px;
  --h1-font-weight: 600;

  --h2-font-size: 24px;
  --h2-line-spacing: 29px;
  --h2-font-weight: 600;

  --h3-font-size: 20px;
  --h3-line-spacing: 30px;
  --h3-font-weight: 700;

  --h4-font-size: 16px;
  --h4-line-spacing: 27px;
  --h4-font-weight: 700;

  --b1-font-size: 14px;
  --b1-line-spacing: 22px;
  --b1-font-weight: 400;

  --b2-font-size: 12px;
  --b2-line-spacing: 24px;
  --b2-font-weight: 400;

  --b3-font-size: 10px;
  --b3-line-spacing: 15px;
  --b3-font-weight: 400;

  --b4-font-size: 9px;
  --b4-line-spacing: 13px;
  --b4-font-weight: 400;

  /* Ui Kit border radius */
  --border-radius: 10px;
  --border-radius-50: 50%;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  /* Letter spacing global */
  --letter-spacing: 0;

  --radius-10: 10px;
}

.addbutton:hover {

  background-color: var(--d9e8ec);

}

html {
  scroll-behavior: smooth;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0d6efd !important;
}

.bottom__nav--shadow {
  box-shadow: 0px 2px 22px #0000000f;
}

/* Text Classes / Utilities */

.text--danger {
  color: var(--danger);
}

.text--white {
  color: var(--white);
}

.text--blue {
  color: var(--blue);
}

.text--success {
  color: var(--green);
}



.text--grey {
  color: var(--grey);
}

.text--dark-green {
  color: #097969;
}

.btn.text--dark-green:hover {
  color: #097969 !important;
}

.text--dark-red {
  color: #aa0505;
}

.btn.text--dark-red:hover {
  color: #aa0505 !important;
}


.text--disabled {
  color: var(--disabledText);
}

.text--warning {
  color: var(--warning);
}

.text--grey {
  color: var(--grey);
}

.text--green {
  color: var(--green);
}

.btn__bottom {
  width: 140px;
  height: 40px;
  background-color: #d9e8ec;
}

.btn__bottom--leave {
  width: 60px;
  height: 40px;
  background-color: #d9e8ec;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text--primary {
  color: var(--primary);
}

.text--secondary {
  color: var(--secondary);
}

.text--blue {
  color: var(--blue);
}

.text--d9e8ec {
  color: var(--d9e8ec);
}

.b1 {
  font-size: var(--b1-font-size);
  line-height: var(--b1-line-spacing);
  font-weight: var(--b1-font-weight);
}

.font-13 {
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0px;
}

.b2 {
  font-size: var(--b2-font-size);
  line-height: var(--b2-line-spacing);
  font-weight: var(--b2-font-weight);
}

.b3 {
  font-size: var(--b3-font-size);
  line-height: var(--b3-line-spacing);
  font-weight: var(--b3-font-weight);
}

.b4 {
  font-size: var(--b4-font-size);
  line-height: var(--b4-line-spacing);
  font-weight: var(--b4-font-weight);
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  line-height: var(--h1-line-spacing);
  color: var(--terciary);
  letter-spacing: 0;
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  line-height: var(--h2-line-spacing);
  color: var(--terciary);
  letter-spacing: 0;
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
  line-height: var(--h3-line-spacing);
  color: var(--terciary);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
  line-height: var(--h4-line-spacing);
  color: var(--terciary);
}

.h18 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: var(--terciary);
}

.text--small {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
}

.text--lightgreen {
  color: var(--d9e8ec);
}

.text--terciary {
  color: var(--terciary);
}

.text--red {
  color: var(--red);
}

.font-8 {
  font-size: 8px;
  letter-spacing: 0px;
}

.font-10 {
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0px;
}

.font-14 {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
}

button:hover {
  filter: brightness(95%) !important;
}

button.btn.icon-btn:hover {
  filter: brightness(100%);
}

.font-16 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
}

.font-18 {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
}

.font-20 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
}

.font-28 {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0px;
}

.font-22 {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0;
}

.font-24 {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-12 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
}

.font-32 {
  font: normal normal 600 32px/39px Montserrat;
  letter-spacing: 0px;
}

.font-48 {
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0;
}

.fw-thin {
  font-weight: normal;
}

.fw-sb {
  font-weight: 600;
}

.fw-400 {
  font-weight: 400;
}

.font-15 {
  font-size: 15px;
}

.br-7 {
  border-radius: 7px;
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: var(--border-radius);
}

.br-50 {
  border-radius: 50%;
}

.mn {
  margin-top: -5px;
}

.bg--blue {
  background-color: var(--blue);
}

.bg--lightBlue {
  background-color: var(--lightBlue);
}

.bg--terciary {
  background-color: var(--terciary) !important;
}

.bg--black {
  background-color: var(--black) !important;
}

.bg--red {
  background-color: var(--red);
}

.bg--green {
  background-color: var(--green);
}

.bg--disabled {
  background-color: var(--disabled) !important;
}

.bg--darkSecondary {
  background-color: var(--darkSecondary);
}

.bg--fafcfc {
  background-color: #fafcfc;
}

.bg--denied {
  background-color: rgba(255, 0, 0, 0.9);
  color: white;
}

.bg--darkblue {
  background-color: var(--darkblue);
}

.modal-height {
  min-height: 50vh !important;
}

.menu__item {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}

.border--bottom {
  border-bottom: 1px solid var(--d9e8ec);
}

.border--top {
  border-top: 1px solid var(--d9e8ec);
}

.border--right {
  border-right: 1px solid var(--d9e8ec);
}

.border--left {
  border-left: 1px solid var(--d9e8ec);
}

/* fieldset{
  display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: solid;
    border-color: var(--terciary-10);
    border-image: initial;
}

fieldset > legend {
  width:auto;
  float: none;
} */

.bg__secondary {
  background-color: var(--secondary);
}

.bg--white {
  background-color: white;
}

.bg--a2d1de {
  background-color: #a2d1de;
}

.bg--primary {
  background-color: var(--primary);
}

.bg--secondary {
  background-color: var(--secondary);
}

.bg--d9e8ec {
  background-color: var(--d9e8ec);
}

.bg--lightGreen {
  background-color: var(--d9e8ec);
}

.btn--border {
  border: 2px solid var(--d9e8ec);
}

.btn--border--red {
  border: 2px solid var(--danger);
}

.box-shadow {
  box-shadow: 0px 2px 12px #0000000f;
}

.bg--background {
  background-color: #fafcfc;
}

.border--default {
  border: 1px solid var(--d9e8ec) !important;
  border-color: var(--d9e8ec) !important;
}

.border--dashed {
  border: 2px dashed var(--d9e8ec);
  border-color: var(--d9e8ec);
}

.border--white {
  border-color: var(--white);
}

.datepicker:focus {
  border-color: var(--white);
}

.mtp-15 {
  margin-top: 7%;
}

.mlp-10 {
  margin-left: 10%;
}

.border--start {
  border-left: 1px solid #d9e8ec;
}

.border-right {
  border-right: 1px solid var(--d9e8ec);
  line-height: 20px;
}

.border--primary {
  border: 2px solid var(--primary);
  border-radius: 10px;
}

.btn--danger {
  background-color: var(--danger);
}

.btn--blue {
  background-color: var(--blue);
}

html {
  scroll-padding-top: 120px;
}

input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0
}

.input:focus {
  border-color: #d9edec;
}

.bg--mild {
  background-color: rgba(51, 99, 131, 0.1);
}

.bg--severe {
  background-color: rgba(236, 96, 155, 0.3);
}

.btn__bottom:hover {
  color: #0d6efd;
}

.btn__bottom:focus {
  box-shadow: none;
  outline: none;
}

.btn__bottom--leave:focus {
  box-shadow: none;
  outline: none;
}

.disabled-link {
  cursor: default;
  pointer-events: none;
  /* background:  #F2F7F8 0% 0% no-repeat padding-box !important; */
  color: #a2d1de !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.bg--moderate {
  background-color: #fff6dd;
}

.text--moderate {
  color: #eaa304;
}

body {
  margin: 0;
  font-family: "Montserrat", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rbt-input {
  box-shadow: none !important;
  border-radius: 10px;
  border-color: var(--d9e8ec);
  outline: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: center;
  background-size: 0.8em;
}

.rbt-input.font-14 {
  font-size: 14px !important;
}

.rbt-input:focus {
  border-color: var(--d9e8ec);
}

.end:hover {
  background-color: #ff0000 !important;
  outline-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.video:hover {
  background-color: #18c383 !important;
  outline-color: #18c383 !important;
  border-color: #18c383 !important;
}

.mic:hover {
  background-color: none !important;
  border-color: white !important;
}

.file-link {
  color: var(--secondary);
  font-weight: 600;
}

.file-link:hover {
  cursor: pointer;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}

.form-select.no-bg {
  background-image: none !important;
}

*:disabled {
  cursor: not-allowed !important;
}


.cursor--disabled {
  cursor: not-allowed !important;
}

button:disabled {
  cursor: not-allowed !important;
}

.pn-card {
  box-shadow: 0px 2px 12px #0000000f;
  border: 1px solid #d9e8ec;
  border-radius: 10px;
}

.disabled-link {
  cursor: default;
  pointer-events: none;
  color: #dddddd;
}

.stripes {
  background: repeating-linear-gradient(-45deg,
      #fff 0px,
      #fff 10px,
      #d9e8ec 10px,
      #d9e8ec 20px);
}

/* Modal Backdrop Color */
.modal-backdrop {
  background-color: #33638333;
}

/* Modal Header and Footer border customization */
.modal-header {
  border-bottom: 1px solid #d9e8ec;
}

.modal-footer {
  border-top: 1px solid #d9e8ec;
}

/* Modal border and box shadow */
.modal-border {
  border: 1px solid #d9e8ec;
  border-radius: 10px;
}

.modal-shadow {
  box-shadow: 0px 3px 16px #0000000f;
}

.card-bg-footer {
  background-color: #f2f7f8;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top: none;
}

.word-wrap {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  width: 100%;
}

.flex-container-scroll {
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 10px;


}

.flex-container-scroll::-webkit-scrollbar {
  background-color: #fafcfc;
  height: 10px;
  margin-top: 10px;

}

.flex-container-scroll::-webkit-scrollbar-thumb {
  background-color: #d9e8ec;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer !important;
}

.dropdown-menu {
  font-size: 13px !important;
}

/*  */
.description-style {
  font-size: 18px;
  font-weight: 600;
}

#pn-provider-encounter-stats {
  width: 50%;
}

#pn-provider-encounter-stats div:nth-child(2) {
  position: relative;
  top: -13px !important;
  font-size: 16px;
}

#pn-provider-messages-stats {
  width: 50%;
}

#pn-provider-messages-stats div:nth-child(2) {
  position: relative;
  top: -13px !important;
  font-size: 16px;
}

#pn-provider-refill-stats {
  width: 50%;
}

#pn-provider-refill-stats div:nth-child(2) {
  position: relative;
  top: -13px !important;
  font-size: 16px;
}

@media screen and (min-width: 1350px) {
  #pn-provider-refills::after {
    content: "";
    background: #edf0f1;
    width: 1px;
    height: 100%;
    position: relative;
    display: block;
    float: right;
    top: -99%;
    left: -20%;
  }
}

@media screen and (max-width:1349px) {
  #pn-provider-encounter-stats div:nth-child(2) {
    position: relative;
    top: -11px !important;
    font-size: 14px;
  }


  #pn-provider-messages-stats div:nth-child(2) {
    position: relative;
    top: -11px !important;
    font-size: 14px;
  }

  #pn-provider-refill-stats div:nth-child(2) {
    position: relative;
    top: -11px !important;
    font-size: 14px;
  }




}

/* #pn-provider-encounters::after {
  content: "";
  background: red;
  width: 1px;
  height: 100%;
  position: relative;
  display: block;
  float: right;
  top: -99%;
  left: -15%;
} */

@media screen and (min-width: 1350px) {
  #pn-provider-encounters::after {
    content: "";
    background: #edf0f1;
    width: 1px;
    height: 100%;
    position: relative;
    display: block;
    float: right;
    top: -99%;
    left: -15%;
  }
}

.white_background {
  background: #ffffff;
}

.refill-modal-width {
  width: 40vw;
}

.medicaiton-history-modal-width {
  width: 35vw;
}

/* Search result scrollbar */
.overflowy-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

/* -----------------Scroll bar--------------------- */
/* .overflowy-auto::-webkit-scrollbar{
  width: 0.5em;
}

.overflowy-auto::-webkit-scrollbar-track{
  background: #FAFCFC;
  border: 1px solid #D9E8EC;
  border-radius: 10px;
  margin-right: -20px;
}

.overflowy-auto::-webkit-scrollbar-thumb{
  background: #D9E8EC;
  border-radius: 10px;
}

.overflowy-auto::-webkit-scrollbar-thumb:hover{
  background: #D9E8EC;
  opacity: 0.8;
  border-radius: 10px;
} */
/* -----------------Scroll bar end--------------------- */

.border-color-d9e8ec {
  border-color: #d9e8ec !important;
}

.checkbox-border-width {
  border-width: 2px;
}

.form-check-input.border-color-d9e8ec.checkbox-border-width:checked {
  /* color:red; */
  background-color: var(--blue);
}

.horizontal-rule {
  border-top: 1px solid #d9e8ec;
}

.cross-sign:hover {
  cursor: pointer;
}

.d9e8ec--button {
  background: #d9e8ec;
  color: #004fd4 !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: .25rem;
  margin-left: .25rem;
  border: 1px solid #d9e8ec;
  width: 12em;
  min-width: max-content !important;
  height: 2.9em !important;
}

.d9e8ec--button.outlined {
  background: transparent !important;
  border: 2px solid #d9e8ec;
  color: #004fd4 !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: .25rem;
  margin-left: .25rem;
  border: 1px solid #d9e8ec;
  width: 12em;
  min-width: max-content !important;
  height: 2.9em !important;
}

.d9e8ec--button:hover {
  background: #e9f3f5 !important;
  color: #004fd4 !important;
  box-shadow: 0px 3px 3.5px #a2d1de66;
  opacity: 1;
  border: 1px solid #e9f3f5;
}

.d9e8ec--button:focus {
  color: #004fd4 !important;
  opacity: 1;
  box-shadow: none;
  /* border: 1px solid #d9e8ec; */
}

.d9e8ec--button:active {
  background: #d9e8ec 0% 0% no-repeat padding-box !important;
  color: #004fd4 !important;
  opacity: 1;
  box-shadow: none;
  /* border: 1px solid #d9e8ec; */
}

.d9e8ec--button:disabled {
  background: #f2f7f8 0% 0% no-repeat padding-box !important;
  color: #a2d1de !important;
  opacity: 1;
  cursor: not-allowed !important;
  border: none;
}

/* Responsive Modifications */

/* @media screen and (max-width: 600px) {
  .d9e8ec--button {
    width: 100%;
    min-width: initial !important;
  }
} */

.blue_task_button {
  background: #004fd4;
  color: #fff !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: .25rem;
  margin-left: .25rem;
  height: 2.9em !important;
  width: 12em;
  min-width: max-content !important;
  border: 1px solid #004fd4;
  box-sizing: border-box;
}

.blue_task_button.outlined {
  background: transparent !important;
  color: var(--blue) !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: .25rem;
  margin-left: .25rem;
  height: 2.9em !important;
  width: 12em;
  min-width: max-content !important;
  border: 1px solid #004fd4;
}

.blue_task_button:hover {
  background: #2c75ef !important;
  color: #ffffff !important;
  box-shadow: 0px 3px 3.5px #004fd466;
  opacity: 1;
  border: 1px solid #004fd4;
}

.blue_task_button:focus {
  color: #ffffff !important;
  opacity: 1;
  box-shadow: none;
  /* border: 1px solid #004fd4; */
}

.blue_task_button:active {
  background: #0038a7 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  opacity: 1;
  box-shadow: none;
  /* border: 1px solid #004fd4; */
}

.blue_task_button:disabled {
  background: #f2f7f8 0% 0% no-repeat padding-box !important;
  color: #a2d1de !important;
  opacity: 1;
  cursor: not-allowed !important;
  border: none;
}

/* Responsive Modifications */

/* @media screen and (max-width: 1500px) {
  .blue_task_button {
    width: 100%;
    min-width: initial !important;
  }

  .d9e8ec--button {
    width: 100%;
    min-width: initial !important;
  }
} */

.button_danger {
  background: var(--danger);
  color: #fff !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: .25rem;
  margin-left: .25rem;
  height: 2.9em !important;
  width: 12em;
  min-width: max-content !important;
  border: 1px solid var(--danger);
}

.button_danger:hover {
  background: #ff6666 !important;
  color: #ffffff !important;
  box-shadow: 0px 3px 3.5px #ff3232;
  opacity: 1;
  border: 1px solid var(--danger);
}

.button_danger:focus {
  color: #ffffff !important;
  opacity: 1;
  box-shadow: none;
  /* border: 1px solid #004fd4; */
}

.button_danger:active {
  background: var(--danger) 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  opacity: 1;
  box-shadow: none;
  /* border: 1px solid #004fd4; */
}

.button_danger:disabled {
  background: #f2f7f8 0% 0% no-repeat padding-box !important;
  color: #a2d1de !important;
  opacity: 1;
  cursor: not-allowed !important;
  border: none;
}

/* 
body {
  background-color: #FAFCFC !important;
} */

.btn.text-decoration-underline:hover {
  color: var(--blue);
}

.border-bottom.border-bottom-color {
  border-bottom-color: #d9e8ec !important;
}

.border-top.border-top-color {
  border-top-color: #d9e8ec !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor--pointer {
  cursor: pointer;
}

.cursor--default {
  cursor: default;
}

.curosor--menu:hover {
  cursor: context-menu !important;
}

.visibility--hidden {
  visibility: hidden;
}

.notifications-list::-webkit-scrollbar {
  display: none;
}

.hide-scrolbar::-webkit-scrollbar {
  display: none;
}

.icon__defualt {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.form-check-input.pn-checkbox:checked {
  background-color: var(--blue);
  border-color: var(--blue);
}

.border.border-default-color {
  border-color: var(--d9e8ec) !important;
}

.border.border-container {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.badge.care-item:hover {
  cursor: pointer;
}

.border-bottom-radius {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-40vw {
  min-width: 28vw;
}

.drawer-toggle {
  padding-top: 1.1em !important;
  padding-bottom: 1.1em !important;
}

.drawer-toggle:hover {
  background: white !important;
}

.drawer-toggle-rotate {
  transform: rotate(180deg) !important;
}

.page::-webkit-scrollbar {
  display: none;
}

.scroll-hide {
  overflow-y: scroll;
}

.scroll-hide::-webkit-scrollbar {
  overflow-y: scroll;
  display: "none" !important;
}

.noscrollbar::-webkit-scrollbar {
  overflow-y: scroll;
  display: none;
}

/* Video Bar  */
.video-bar {
  z-index: 1310;
}

.text-white-70 {
  color: var(--white);
  opacity: 0.7;
}

.video-bar-ps-4 {
  padding-left: 24px;
}

.video-bar-py {
  padding-top: 12px;
  padding-bottom: 12px;
}

.video-bar-call-button-open {
  width: 4em;
  height: 40px;
  background-color: var(--green);
  color: var(--white);
  font-size: 12px;
  letter-spacing: 0;
  border-radius: 10px;
  line-height: 24px;
}

.video-bar-call-button-close {
  width: 4em;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--d9e8ec);
  color: var(--white);
  font-size: 12px;
  letter-spacing: 0;
  border-radius: 10px;
  line-height: 24px;
}

.video-bar-call-control {
  width: 3em;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #d9e8ec;
  vertical-align: middle;
}

.video-bar-mute {
  background-color: transparent;
}

.video-bar-end {
  border-color: var(--danger);
  background-color: var(--danger);
}

.end-consultation-duration {
  border: 1px solid var(--d9e8ec);
  background: var(--disabled) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.end-consultation-modal-dialog {
  width: 420px;
  height: 385px;
}

.w-140 {
  width: 140px;
}

.pn-border {
  border: 2px solid var(--d9e8ec);
  border-radius: 10px;
}

.modal-80vh {
  height: 80vh !important;
  width: 100vw;
}

/* Agora Video Container Join Page CSS */

.jp-subscriber {
  border-radius: 10px;
  min-width: max-content;
  background-color: #d9e8ec;
  height: 430px;
  box-shadow: 0px 3px 16px #0000000f;
}

.pv-subscriber {
  border-radius: 10px;
  min-width: max-content;
  /* background-color: #d9e8ec; */
  height: 530px;
  box-shadow: 0px 3px 16px #0000000f;
}

.pv-agora-video-player {
  height: 530px;
  border-radius: 10px;
  min-width: max-content;
  aspect-ratio: 16/9;
  bottom: 28.5%;
  position: relative;
}

.pv-publisher {
  border-radius: 10px;
  aspect-ratio: 1/1;
  height: 150px;
  position: relative;
  box-shadow: 0px 3px 16px #0000000f;
  top: 67%;
  left: 0.5em;
  z-index: 10;
}

.button-grid {
  position: relative;
  top: 85%;
  z-index: 1000 !important;
}

.button-grid-actions {
  position: relative;
  top: 5%;
  left: 85%;
  z-index: 1000 !important;
}

.modal-90w {
  min-width: 60%;
}

.btn-container {
  border-radius: 10px;
  background-color: #fff;
}

.btn-container:hover {
  filter: brightness(95%);
  cursor: pointer;
}

.btn-icon {
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.btn-icon--negative {
  background-color: var(--danger);
}

.btn-icon--positive {
  background-color: var(--green);
}

.btn-label-text {
  padding-top: 4px;
  padding-bottom: 4px;
}

.video-track-pn {
  bottom: 2em !important;
  border-radius: 10px !important;
  background-color: white !important;
  box-shadow: 0px 3px 16px #0000000f !important;
}

.join-now-button {
  width: 260px;
  height: 45px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: var(--blue);
  color: white;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
}

.leave-session-button {
  width: 260px;
  height: 45px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: var(--danger);
  color: white;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
}

textarea.text-area-scrollbar::-webkit-scrollbar {
  display: none;
}

.offscreen {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.si {
  bottom: 1.5em;
  border-radius: 10px;
}

.cf {
  height: 220px !important;
  width: 380px !important;
}

.border-end.waiting-room-controls {
  border-right: 1px solid var(--d9e8ec) !important;
}

label[for="file"] {
  width: 100% !important;
}

.z-1020 {
  z-index: 1020 !important;
}

ul {
  list-style: square;
}

.br-100 {
  border-radius: 50%;
}

.border-outlined-blue {
  border: 1px solid var(--blue);
  color: var(--blue);
}

.border-outlined-primary {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.border-outlined-danger {
  border: 1px solid var(--danger);
  color: var(--danger);
}

.icon--disabled {
  color: var(--bcd);
}

.btn-outline-primary.btn-outline-blue {
  border-color: var(--blue);
  color: var(--blue);
}

.btn-outline-primary.btn-outline-blue:hover {
  background-color: var(--blue);
  color: white;
}

.padding-4em {
  padding-top: 4.5em !important;
}

.border--error {
  border: 1px solid var(--danger) !important;
}

.row--border {
  border-top: 1px solid #d9e8ec;
  border-left: 1px solid #d9e8ec;
  border-right: 1px solid #d9e8ec;

}


.border--bottom-appointments {
  border-bottom: 1px solid var(--d9e8ec);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pn-link-button:hover {
  /* color: var(--blue) !important; */
  text-decoration: underline !important;
}

.pn-button-icon-blue:hover {
  color: var(--blue) !important;
}

.pn-button-icon-secondary:hover {
  color: var(--secondary) !important;
}

.pn-link-button-danger:hover {
  color: var(--red);
}


.outlined:focus {
  color: #004fd4 !important;
}



.react-datepicker__close-icon {
  color: white !important;
  background-color: transparent !important;
  opacity: 0;
}

.zindex-1 {
  z-index: 1 !important;
}

/* For Edit button in patient insurance section */
.mb-02em {
  margin-bottom: 0.2em !important;
}

.form-control.not-writable:disabled {
  background-color: white !important;
  cursor: pointer !important;
}

ul.list-rounded {
  list-style-type: disc !important;
}

.telemedicine-canvas {
  border: 1px solid #d9e8ec;
  border-radius: 10px;
}

/* Big Calendar related CSS */
.rbc-event {
  background-color: #336383 !important;
  font-size: 12px !important;
  border-color: #d9e8ec !important;
}

.rbc-event.rbc-selected {
  background-color: #235170 !important;
}

.rbc-event:focus {
  outline-color: #466f8b !important;

}

.rbc-month-view {
  border-radius: 10px !important;
  border-color: #d9e8ec !important;
}

.rbc-date-cell.rbc-now>button {
  background-color: #ec609b !important;
  border-radius: 50%;
  width: 1.5em !important;
  height: 1.5em !important;
  color: white;

}

button.rbc-active {
  background-color: #E9F3F5 !important;
  box-shadow: none !important;
  border-color: #d9e8ec !important;
}

.rbc-toolbar {
  font-size: 14px !important;
}

.rbc-toolbar-label {
  font-weight: 600 !important;
  color: #336383 !important;
}

button.clickable:focus {
  background-color: transparent !important;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
  border-color: #d9e8ec !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.rbc-btn-group>button:last-child:not(:first-child) {
  border-color: #d9e8ec !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.rbc-btn-group>button:first-child:not(:last-child) {
  border-color: #d9e8ec !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.rbc-day-bg {
  border-color: #d9e8ec !important;
}

.rbc-month-row {
  border-color: #d9e8ec !important;
}

.rbc-header {
  font-size: 14px !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  border-color: #d9e8ec !important;
}

/* File Edit Document Modal */
.modal-top-5 {
  top: 13% !important;
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in-appointment {
  animation: fade-in-animation 0.3s ease-in-out;
}


input[type="text"].font-12.simple-placeholder::placeholder {
  font-size: 12px !important;
  font-style: normal !important;
}

.text-decoration-underline-secondary {
  text-decoration: underline;
}

.patient-billing-left {
  background-color: #D9E8EC;
}


/* Billing Dashboard */
.badge-success {
  background-color: var(--green);
  color: white;
}

.badge-pending {
  background-color: var(--warning);
  color: white;
}

.badge-rejection {
  background-color: var(--red);
  color: white;

}

.badge-in-progress {
  background-color: var(--secondary);
  color: white;

}


.custom-gap-2-5 {
  gap: 2rem !important; /* Adjust as per your preferred unit */
}

.verified-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-color: #336383;
  border-radius: 50%; /* Makes it a circle */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.verified-icon {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 44%;
  left: 45%;
  transform: translate(-50%, -50%);
  fill: none;
}
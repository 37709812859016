.input[type='radio'] {
    display: none;
}

.input[type='radio']:checked {
    border-color: 3px solid var(--secondary) !important;
    border-radius: 10px !important;
    outline: inherit;
}

.ml-10 {
    margin-left: 0px;
}
input[type="email"]::placeholder{
    
    font-size:14px;
    font-weight: 400;
    font-family: 'Montserrat';
}

input[type="email"]{
    font-family: 'Montserrat';
}

.loginBtn{
    font-family: 'Montserrat';
    background: #004fd4;
    color: #fff !important;
    border-radius: 10px;
    
}

.loginBtn:hover{
    background: #2c75ef !important;
    color: #ffffff;   
    box-shadow: 0px 3px 3.5px #004fd466;
    opacity: 1;
    border: 1px solid #004fd4;
}
  
.loginBtn:focus {
color: #ffffff !important;
opacity: 1;
box-shadow: none;
/* border: 1px solid #004fd4; */
}

.loginBtn:active {
background: #0038a7 0% 0% no-repeat padding-box !important;
color: #ffffff !important;
opacity: 1;
box-shadow: none;
/* border: 1px solid #004fd4; */
}
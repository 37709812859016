
  
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5; /* adjust color if needed */
    text-align: center;
    padding: 10px 0;
  }
  
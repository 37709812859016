.loginBtn {
    font-family: 'Montserrat';
    font-size: 14px;
    border: 1px solid #004FD4;
}


.loginmodal__size {
    min-width: 350px;
}

/* Modal Backdrop Color */
.modal_backdrop {
    background-color: #33638333;
}

/* Modal Header and Footer border customization */
.modal-header {
    border-bottom: 1px solid #d9e8ec;
}

.modal-footer {
    border-top: 1px solid #d9e8ec;
}

/* Modal border and box shadow */
.modal_border {
    border: 1px solid #D9E8EC;
    border-radius: 10px;
}

.modal_shadow {
    box-shadow: 0px 3px 16px #0000000F;
}

.search__container{
    padding: 1.2px;
}
.input:focus {
    border-color: #d9edec;
}

.sig:focus{
    border-color: #d9edec;
}

select option:checked  {
    background-color: #d9e8ec;
}


.checkbox-accent:checked{
    background-color: #d9e8ec;
    box-shadow: 0 0 0 0.1rem var(--bcd);
    border-color: #d9e8ec;
}

.checkbox-accent:focus{
    box-shadow: 0 0 0 0.1rem var(--bcd);
    outline: none;

}

.checkbox-accent:indeterminate{
    background-color: white;
    box-shadow: none;
    outline: none;
}

.sig__info--border{
    box-shadow: 0px 3px 6px #0000000F;
border: 2px solid #D9E8EC;
border-radius: 10px;
}
.suggestion__list{
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border: 0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index:5;
    background-color: var(--white);
}
.suggestion__list li{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #d9e8ec;
}

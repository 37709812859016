.notification__container{
    background: var(--darkSecondary) 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 11px #13446533 ;
    border-radius: 10px;
    opacity: 1;
    height: 10vh;
}
.notification__container__shadow{
    border-bottom:12px solid #618BA7;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    max-width: 97%;
    
}

.alert__icon{
    color: var(--primary);
    height: 30px !important;
    width: 30px !important;
}
.alert__close{
    line-height: 12px;
    width: 18px;
    font-size: 8pt;
    color: #A2D1DE;
     /* position: "relative",
     top: 5,
     right: 5, */
    cursor: pointer;
}
.scroll__bar {
    max-height: 250px;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-color: white;
}

.scroll__bar::-webkit-scrollbar {
    width: 0.5em;
}

.scroll__bar::-webkit-scrollbar-track {
    background: #FAFCFC;
    border: 1px solid #D9E8EC;
    border-radius: 10px;
}

.scroll__bar::-webkit-scrollbar-thumb {
    background: #D9E8EC;
    border-radius: 10px;
}

.scroll__bar::-webkit-scrollbar-thumb:hover {
    background: #D9E8EC;
    opacity: 0.8;
}

.cpt_suggestions {
    list-style-type: none;
    width: 100%;
    padding-left: 0;
    margin: 2px 0 0;
    position: relative;
    z-index: 10;
}

.search__list__item {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 4px;
    display: flex;
    font-family: Montserrat;
    font-size: 12px;
    border-bottom: 1px solid #d9e8ec;
}

.li:hover {
    background-color: rgba(211, 211, 211, 0.2);
}

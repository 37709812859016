.alert__icon__container{
    border: 1px solid var(--d9e8ec);
    padding: 5px;
    cursor: pointer;
}
.alert__icon{
    color: var(--secondary);
    height: 30px ;
    width: 30px ;
}
.alert__icon__active{
    color: var(--primary);
    height: 30px ;
    width: 30px ;
}

.stat__value{
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: -8px;
    left: 0%;
    /* background: #336383; */
    width: 20px;
    border-radius: 100px;
    height: 20px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat__icon{
    border-radius: 16px;
    padding: 12px;
}


.description-style {
    font-size: 18px;
    font-weight: 600;
}
.dropdown__menue__items{
    z-index:  999999;
    box-shadow: 0px 9px 13px #33638326;
    border: 1px solid #D9E8EC;
    border-radius: 0px 0px 10px 10px;
    min-width: 18vw;
    margin-left:-232px;
    margin-top:14px;
    max-height: 50vh;
    overflow-y: auto;
}

.scroll__bar::-webkit-scrollbar{
    width: 0.5em;
    height: 0.5em;
  }
  
  .scroll__bar::-webkit-scrollbar-track{
    background: #FAFCFC;
    border: 1px solid #D9E8EC;
    border-radius: 10px;
    /* margin-right: -20px; */
  }
  
  .scroll__bar::-webkit-scrollbar-thumb{
    background: #D9E8EC;
    border-radius: 10px;
  }
  
  .scroll__bar::-webkit-scrollbar-thumb:hover{
    background: #D9E8EC;
    opacity: 0.8;
    border-radius: 10px;
  }
.custom-modal-width {
    max-width: 20vw;
}

.custom-modal-height {
    max-height: 40vh;
}

.next_button {
    width: 239.5/16rem;
}
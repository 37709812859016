.action__button {
    /* padding-right: 16px;
    padding-left: 16px; */
    min-width: 100px;
    border-radius: 0.5rem;
}

.timeline {
    border-left: 1px solid #D9E8EC;
    position: relative;
    list-style: none;
}

.timeline .timeline-item {
    position: relative;
}

.timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .timeline-item:after {
    background-color: #D9E8EC;
    left: -38px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
}

.chevron__left__div{
    height: 37px;
    width: 38px;
}
.patient-call-wrapper {
    position: relative;
    /* background-color: yellow; */
    height: calc(100vh - 240px);
    width: 90%;
    margin: 0 auto;
    /* border:1px solid #d9e8ec;
    border-radius: 7px; */
}

.call-main-row {
    bottom: 0;
    left: 0;
    overflow: auto;
    padding-bottom: inherit;
    padding-top: inherit;
    position: absolute;
    right: 0;
    top: 0;
}

#patient-portal-publisher {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #d9e8ec;
    border-radius: 7px;
    background-color: antiquewhite;
    z-index: 60;
}

#patient-portal-publisher>div {
    border-radius: 7px;
}

#patient-portal-subscriber {
    position: absolute;
    /* background-color: tomato; */
    border: 2px solid #d9e8ec;
    border-radius: 7px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 40;
}

#patient-portal-subscriber>div {
    border-radius: 7px;
}

#patient-portal-subscriber-initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.patient-control-btn {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50% !important;
    background-color: #354955;
    color: white;
}

.patient-control-btn:hover {
    color: white;
}
.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

}

select::placeholder {
    font-style: italic;
    font-size: 14px;
}
.chevron__left__div{
    height: 37px;
    width: 38px;
}

.service__charges::placeholder{
    font-style: italic;
}
.service__charges::before {
    content: "$"; /* Content of the prefix */
    position: relative;
    left: 10px; /* Adjust the left position as needed to place the prefix where you want */
    font-weight: bold; /* Optionally, make the prefix text bold */
    color: #333; /* Set the color of the prefix text */
  }
.service-list:first-child > div{
    background-color: red;
}
  
.suggestion__list{
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 250px;
    border: 0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index:5;
    background-color: var(--white);
}
.suggestion__list li{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 14px;
    border-bottom: 1px solid #d9e8ec;
}
.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

}

select::placeholder {
    font-style: italic;
    font-size: 14px;
}

.custom-modal-style {
    width: 65vw;
    max-width: 90vw;
}

.appointmentmodal__style {
    box-shadow: 0px 3px 16px #0000000F;
    border: 1px solid #D9E8EC;
    border-radius: 10px;
}

.appointmentmodal__border--bottom {
    border-bottom: 1px solid #d9e8ec;
}

.start__time__input {
    color: var(--blue);
    background: var(--disabled);
    font-weight: 600;
    border-radius: 10px;
    font-family: Montserrat;
    text-transform: capitalize;
    border: 1px solid var(--d9e8ec);
    width: 100%;
    text-align: center
}

.duration {
    border: 1px solid #D9E8EC !important;
    height: 40px;
    border-radius: 10px;
    width: 9vw;
}

.scroll__bar::-webkit-scrollbar {
    width: 0.5em;
}

.scroll__bar::-webkit-scrollbar-track {
    background: #FAFCFC;
    border: 1px solid #D9E8EC;
    border-radius: 15px;
    margin-right: -20px;
}

.scroll__bar::-webkit-scrollbar-thumb {
    background: #D9E8EC;
    border-radius: 15px;
}

.scroll__bar::-webkit-scrollbar-thumb:hover {
    background: #D9E8EC;
    opacity: 0.8;
    border-radius: 15px;
}


input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
    outline: none;
}
input[type="time"]:focus{
    outline: none;
}

/* input[type="time"]::-webkit-datetime-edit-ampm-field {
    display:none;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url('../../../../assets/icons/Icon-Calendar.svg');
    margin-right: "0px";
    margin-left: "0px";
}

.custom-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ec609b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        
}

.modal__size {
    min-width: 900px !important;
    /* height: 500px !important; */
    right: 12%
}

select::placeholder{
    font-style: italic;
    font-size:14px;
}

.scheduling-grid {
    display: grid;
    grid-template-rows: min-content;
    grid-auto-flow: column;
    /* grid-auto-columns: 24%; */
    overflow-x: auto;
    overscroll-behavior-inline: contain;
}
.scheduling-grid-col-1 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    /* width: 210px; */
}
.scheduling-grid-col-2 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-left-color: #fff;
}
.scheduling-grid-col-3 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-left-color: #fff;
}
.scheduling-grid-col-4 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-left-color: #fff;
}
.scheduling-grid-col-5 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-left-color: #fff;
}
.scheduling-grid-col-6 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-left-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.scheduling-grid-col-6-sun {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-left-color: #fff;
}
.scheduling-grid-col-7 {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-right-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.scheduling-grid-col-7-mon {
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #D9e8ec;
    border-right-color: #D9e8ec;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.scheduling-grid::-webkit-scrollbar {
    background-color: #fafcfc;
    height: 10px;
}

.scheduling-grid::-webkit-scrollbar-thumb {
    background-color: #d9e8ec;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer !important;
  }

.modal-body::-webkit-scrollbar {
    width: 0px;
}

.scheduling-grid-col-1::-webkit-scrollbar {
    width: 0px;
}
.scheduling-grid-col-2::-webkit-scrollbar {
    width: 0px;
}
.scheduling-grid-col-3::-webkit-scrollbar {
    width: 0px;
}
.scheduling-grid-col-4::-webkit-scrollbar {
    width: 0px;
}
.scheduling-grid-col-5::-webkit-scrollbar {
    width: 0px;
}
.scheduling-grid-col-6::-webkit-scrollbar {
    width: 0px;
}
.scheduling-grid-col-7::-webkit-scrollbar {
    width: 0px;
}

.custom-modal-style {
    /* width: 60vw;
    max-width: 90vw; */
    min-height: 30vh;
}

.modal__style {
    box-shadow: 0px 3px 16px #0000000F;
    border: 1px solid #D9E8EC;
    border-radius: 10px;
}

.appointmentmodal__border--bottom {
    border-bottom: 1px solid #d9e8ec;
}
.make__scrollable{
    position: "absolute";
    height:"60vh";
    overflow:"scroll";
}

input[type="text"]::placeholder{
    font-style: italic;
    font-size:14px;
    color:'#dddddd';
    opacity: 0.5;
}
.searchBar{
    height: 40px;
}
.suggestion__list{
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    list-style: none;
    overflow: auto;
    max-height: 180px;
    border: 0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index:5;
    background-color: var(--white);
    border-radius: 7px;
}
.suggestion__list li{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    display: flex;
    font-family: Montserrat;
    font-size: 12px;
    border-bottom: 1px solid #d9e8ec;
    
}
.delete__icon{
    height: 16px;
    width: 16px;
}







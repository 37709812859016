.conference-container {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  /* align-content: stretch; */
  width: 100%;
  height: 38.75em;
  border-radius: 10px;
}

.background {
  background-color: var(--darkblue);
}

.conference-item:nth-child(1) {
  /* background-color: aqua; */
  order: 0;
  flex: 0 1 18%;
  max-height: 90% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 1em;
}

.conference-item:nth-child(1)::-webkit-scrollbar {
  /* background-color: aqua; */
  display: none;
}

.conference-item:nth-child(2) {
  /* background-color: #336383; */
  order: 1;
  flex: 1 1 57%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.conference-item:nth-child(2) > div.speaker {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.conference-item:nth-child(3) {
  order: 3;
  flex: 0 1 25%;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-right: 1em;
}

.conference-item:nth-child(1) > div.subscriber-list {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 12px;
  padding-left: 4px;
  padding-right: 4px;
}

.subscriber-item {
  width: 100%;
  height: 160px;
  /* aspect-ratio: 1/1; */
  border-radius: 10px;
  background-color: #031b2b;
}

.subscriber-name {
  position: relative;
  top: 85%;
  left: 10px;
  z-index: 1;
}

.speaker-video {
  width: 90%;
  height: 480px;
  border-radius: 10px;
  /* background-color: #d9e8ec; */
}

.speaker-top-controls {
  position: relative;
  top: 0;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 0.5em;
  padding-top: 0.8em;
  z-index: 100;
}

.expand-icon {
  background-color: black;
  opacity: 0.1;
  display: inline-flex;
  padding: 4px;
  border-radius: 0.2em;
  cursor: pointer;
  z-index: 100;
}
.focused-participant-name {
  position: relative;
  top: 27em;
  left: 10px;
}

.focused-participant-name--collapse {
  position: relative;
  top: 27em;
  left: 10px;
}

.control-btn {
  width: 3.438em;
  height: 3.438em;
  background-color: #354955;
  color: white;
}

.control-btn:hover {
  color: white;
}

.positiveInputContainer {
    display: flex;
    justify-content: flex-start;
    margin: 20px;
    flex-direction: column;
}

.positiveInputWrapper {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.positiveInput {
    border-radius: 10px;
    border: 1px solid #d9e8ec;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    padding-right: 20px;
}

.positiveInput:focus {
    outline: none;
}

.unit {
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    min-width: fit-content;
    font-size: 10px;
    color: #336383 !important;
    font-family: 'Montserrat';
    text-align: center;
}

@media (max-width: 768px) {
    .positiveInputContainer {
        flex-direction: column;
    }
}
.modal__button {
    border: none;
    border-radius: 10px;
}

.modal__border {
    border: 1px solid #d9e8ec;
    box-shadow: 0px 8px 16px #00000014;
}

.modal__backdrop {
    background-color: #33638333;
    z-index: 1055;
}

.modal__button {
    width: 272px !important;
    height: 48px !important;

}
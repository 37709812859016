#videos {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;

}

#subscriber {
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    height: 100%;
    z-index: 99;
    border-radius: 10px;
}

#publisher {
    position: absolute;
    z-index: 100;
    /* border: 3px solid white;
    border-radius: 3px; */
}




#subscriber {
    background-color: #DEDFE4;
    border-radius: 10px;
    box-shadow: 0px 8px 16px #00000014;

}

#controls {
    margin: auto;

}

/* #startcall , #endcall
{
    margin: 10px;
} */

#videos {
    width: 20%;
    height: 25%;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    z-index: 1045;
}

/* #publisher
{
    width: 30%;
    height: 30%;
} */

#expandDiv {
    color: #ffffff;
    background: #000000 0% 0% no-repeat padding-box;
    padding: 5px;
    opacity: 0.1;
    border-radius: 8px;
    margin-top: 2.5%;
    margin-left: 1.5%;
    position: absolute;
    z-index: 250 !important;
}

#expandDiv:hover {
    cursor: pointer;
}

.no-select {
    user-select: none;
}